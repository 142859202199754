import jQuery from 'jquery';
import Swiper from 'swiper';
//From slider.php file

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore -- gsap types are not included as it's an external package
const gsap_temp = gsap;

function addFloatingBuyButton(): void {
  const button = document.createElement('Button');
  button.id = 'floating_buy_button_container';
  button.innerHTML =
    '<a class="button-primary" href="https://shop.embarkvet.com/products/dog-breed-ancestry"">Shop&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="26" height="11" viewBox="0 0 26 11" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.3698 4.49999L19.8356 2.2474C19.4229 1.88048 19.3857 1.24841 19.7526 0.835626C20.1195 0.422843 20.7516 0.385662 21.1644 0.752581L25.653 4.7425C25.6715 4.7585 25.6896 4.77529 25.7071 4.79286C25.7892 4.87481 25.8543 4.96797 25.902 5.06768C25.9676 5.20441 26.0001 5.35238 26 5.49999C25.9999 5.75731 25.9011 6.01351 25.7071 6.20712C25.6896 6.22469 25.6715 6.24149 25.653 6.2575L21.1644 10.2474C20.7516 10.6143 20.1195 10.5771 19.7526 10.1644C19.3857 9.75157 19.4229 9.1195 19.8356 8.75258L22.3698 6.49999L1.5 6.49999C0.947716 6.49999 0.5 6.05227 0.5 5.49999C0.5 4.9477 0.947716 4.49999 1.5 4.49999L22.3698 4.49999Z"></path></svg></a>';
  button.setAttribute('style', 'top:90%; right:-200px; position:fixed; z-index: 10; transition:all 0.6s ease-in-out;');
  document.body.appendChild(button);

  const intersectionObserver = new IntersectionObserver(
    function (entries) {
      if (entries[0].isIntersecting !== true && !(entries[0].boundingClientRect.y < 0)) {
        toggle_floating_buy('off');
        return;
      }
      toggle_floating_buy('on');
    },
    {
      root: null,
      threshold: 0.25,
    }
  );

  const visibilityTrigger = document.querySelector('.breed-reveal__halo');
  if (visibilityTrigger instanceof HTMLElement) {
    intersectionObserver.observe(visibilityTrigger);
  }
}

function toggle_floating_buy(toggle = 'on'): void {
  const toggleValue = toggle === 'on' ? '20px' : '-200px';

  const buyButton = document.getElementById('floating_buy_button_container');
  if (buyButton) {
    buyButton.style.right = toggleValue;
  }
}

function initChatSectionAnimations(): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  const timeline = gsap_temp.timeline({
    scrollTrigger: {
      trigger: document.querySelector('.breed-reveal__chat'),
      pin: true,
      scrub: true,
      start: () => {
        if (window.matchMedia('(max-width: 639px)').matches) {
          return '-20px top';
        }
        return 'top top';
      },
      end: () => '+=100%',
    },
  });

  const messages = document.querySelectorAll('.chat__bubble');
  timeline.set(messages, {
    opacity: 0,
    y: '40%',
    scale: 0.5,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  const messagesTimeline = gsap_temp.timeline();
  messagesTimeline.to(messages, {
    opacity: 1,
    y: 0,
    scale: 1,
    stagger: 1.2,
  });

  timeline.add(messagesTimeline, '+=1%');
}

function initFamilyTreeSectionAnimations(): void {
  const elements = [
    {
      trigger: '.breed-reveal__hero',
      selector: '.breed-reveal__hero .hero-copy',
    },
    {
      trigger: '.breed-reveal__halo',
      selector: '.breed-reveal__halo__copy',
    },
    {
      trigger: '.breed-reveal__flip',
      selector: '.breed-reveal__flip--copy',
    },
    {
      trigger: '.breed-reveal__chat--animated',
      selector: '.breed-reveal__chat__content',
    },
  ];

  elements.forEach((element) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- gsap types are not included as it's an external package
    const floatInTimeline = gsap_temp.timeline({
      scrollTrigger: {
        trigger: element.trigger,
        start: () => {
          return 'center bottom';
        },
      },
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- gsap types are not included as it's an external package
    gsap_temp.set(element.selector, {
      y: 200,
      opacity: 0,
    });

    floatInTimeline.to(element.selector, {
      y: 0,
      opacity: 1,
      duration: 1,
      stagger: {
        each: 0.2,
      },
    });
  });

  // Family tree rings Parallax effect - START
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  const familyTreeRingsTimeline = gsap_temp.timeline({
    scrollTrigger: {
      trigger: '.breed-reveal__flip--bubbles',
      start: '-400px top',
      end: 'bottom top',
      scrub: true,
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  gsap_temp.utils.toArray('.ring').forEach((ring) => {
    const y = ring.offsetHeight * ((Math.random() + 0.1) * 3);
    familyTreeRingsTimeline.to(
      ring,
      {
        y,
        ease: 'none',
      },
      0
    );
  });
  // Family tree rings Parallax effect - END

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  gsap_temp.set('.rect_ring', {
    transformOrigin: 'center center',
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  gsap_temp.to('.rect_ring', {
    scale: 2.25,
    opacity: 0,
    duration: 4,
    stagger: {
      each: 1,
      repeat: 3,
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  const pulseTimeline = gsap_temp.timeline({
    scrollTrigger: {
      trigger: '.breed-reveal__flip--bubbles',
      top: 'center center',
    },
  });

  pulseTimeline
    .to('.flip-front__ring', {
      scale: 1.75,
      opacity: 0,
      duration: 4,
      stagger: {
        each: 1,
        repeat: 20,
      },
    })
    .repeatDelay(10);

  const all: any[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  gsap_temp.utils.toArray('.flipable').forEach(function (bubble) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- gsap types are not included as it's an external package
    gsap_temp.set(bubble, {
      transformStyle: 'preserve-3d',
      transformPerspective: 1000,
      cursor: 'pointer',
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- gsap types are not included as it's an external package
    const q = gsap_temp.utils.selector(bubble);
    const front = q('.flip-front');
    const back = q('.flip-back');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- gsap types are not included as it's an external package
    gsap_temp.set(back, { rotationY: -180 });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- gsap types are not included as it's an external package
    const tl = gsap_temp
      .timeline({ paused: true })
      .to(front, {
        duration: 1,
        rotationY: 180,
        onStart: () => {
          back[0].style.visibility = 'visible';
          front[0].style.visibility = 'visible';
        },
        onComplete: () => (front[0].style.visibility = 'hidden'),
      })
      .to(
        back,
        {
          duration: 1,
          rotationY: 0,
          onStart: () => {
            back[0].style.visibility = 'visible';
            front[0].style.visibility = 'visible';
          },
          onComplete: () => (front[0].style.visibility = 'visible'),
        },
        0
      )
      .to(bubble, { z: 50 }, 0)
      .to(bubble, { z: 0 }, 0.5);
    all.push(tl);

    front[0].addEventListener('click', function () {
      all.forEach((t) => t.reverse());
      tl.play();
      pulseTimeline.time(0).kill();
      back[0].focus();
      back[0].setAttribute('aria-expanded', true);
    });

    back[0].addEventListener('click', function () {
      tl.reverse();
      front[0].focus();
    });

    tl.eventCallback('onReverseComplete', () => {
      front[0].style.visibility = 'visible';
      back[0].style.visibility = 'hidden';
      back.forEach((bubbleBack: HTMLElement) => bubbleBack.setAttribute('aria-expanded', 'false'));
    });
  });
}

function pauseVideoOnModalClose(): void {
  // these events unfortunately are jQuery events, so we need to use jQuery to listen to them
  jQuery('[id^=videoModal_]').on('closed.zf.reveal', () => {
    const videoModalContainers = jQuery('[id^=videoModal_]').find('video');
    if (videoModalContainers.length > 0 && videoModalContainers[0] instanceof HTMLVideoElement) {
      videoModalContainers[0].pause();
    }
  });
}

function setCardActive(swiper: Swiper) {
  if (!swiper || !swiper.realIndex || !swiper.slides) {
    return;
  }

  const currentSlide = swiper.slides[swiper.realIndex];

  if (!(currentSlide instanceof HTMLElement)) {
    return;
  }

  if (!currentSlide || currentSlide.dataset.attachesto === undefined) {
    return;
  }

  document.querySelectorAll('.breed-card').forEach((card) => {
    card.classList.remove('active');
  });
  document.getElementById(currentSlide.dataset.attachesto)?.classList.add('active');
  const x = [-40, 10, 43];
  const y = [25, 0, 35];
  const z = [5, 4, 3];
  const r = [10, 5, 20];

  gsap_temp.utils.toArray('.breed-card').forEach((item: any, key: any) => {
    gsap_temp.to(item, {
      x: x[key],
      y: y[key],
      rotate: r[key],
      scale: 1,
      zIndex: z[key],
      background: '#ffe8a5',
    });
  });
  gsap_temp.to('.breed-card.active', {
    x: -10,
    y: 25,
    scale: 1.1,
    zIndex: 10,
    rotate: -10,
    background: 'white',
  });
}

function setAriaCurrentOnActivePagination(swiper: Swiper) {
  const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet');

  paginationBullets.forEach((bullet) => {
    (bullet as HTMLElement).setAttribute('aria-current', 'false');
  });

  const activeBullet = paginationBullets[swiper.realIndex];
  if (activeBullet) {
    (activeBullet as HTMLElement).setAttribute('aria-current', 'true');
  }
}

export function initBreedReveal() {
  addFloatingBuyButton();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- gsap types are not included as it's an external package
  gsap_temp.registerPlugin(ScrollTrigger);
  initChatSectionAnimations();
  initFamilyTreeSectionAnimations();

  pauseVideoOnModalClose();

  new Swiper('.testimonials__slides', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    effect: 'fade',
    on: {
      init() {
        //eslint-disable-next-line @typescript-eslint/no-this-alias
        const swiper = this as unknown as Swiper;
        setCardActive(swiper);
        setTimeout(() => setAriaCurrentOnActivePagination(swiper), 50);
        document.querySelectorAll('.breed-card').forEach((card) => {
          card.addEventListener('click', () => {
            if (!(card instanceof HTMLElement)) {
              return;
            }

            const slideId = card.dataset.attachedto;

            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const slide = Object.values(swiper.slides)
              .filter((slide: any) => {
                return slide.id === slideId;
              })
              .shift();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const nextSlideIndex = slide.id.replace('slide_', '');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            swiper.slideTo(nextSlideIndex);
          });
        });
      },
      slideChange() {
        const swiper = this as unknown as Swiper;
        setCardActive(swiper);
        setAriaCurrentOnActivePagination(swiper);
      },
    },
  });
}
